import React, { useContext } from 'react'
import Price from './price'
import PriceStriked from './price-striked'
import ESW_CLASSNAMES from '../esw/classnames'
import AppContext from '../context/app'

export default function PriceDisplay(props) {
    const { cart } = useContext(AppContext)

    const {
        item,
        product,
        quantity,
        className,
        eswPriceClassName,
        eswPriceStrikedClassname,
        style
    } = props

    return (
        <p
            style={style}
            className={className}
        >
            {quantity && <span className={`${ESW_CLASSNAMES.CARTITEM_QUANTITY} visually-hidden`}>{quantity}</span>}

            <Price
                item={item}
                product={product}
                quantity={quantity}
                eswPriceClassName={eswPriceClassName}
            />

            <PriceStriked
                item={item}
                product={product}
                quantity={quantity}
                eswPriceStrikedClassname={eswPriceStrikedClassname}
                style={style?.fontSize ? { fontSize: style.fontSize * 0.8 } : null}
            />

            {Number(item?.discount_applied) > 0 && <span
                style={{ fontSize: style.fontSize * 0.7 }}
                className='text-secondary mt-1'
            >
                [{cart?.voucher_code}]
            </span>}
        </p>
    )
}
