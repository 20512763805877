import { useContext, useEffect, useState } from 'react'
import AppContext from '../context/app'
import { BASE_COUNTRY_ISO } from '../../gatsby-node/constants'

export default function useGetPrice(product) {
    const { country, prices, promotions } = useContext(AppContext)
    const [price, setPrice] = useState(null)
    const [baseprice, setBaseprice] = useState(null)
    
    useEffect(() => {
        if (!product || !country || !prices || !promotions) return
        try{
            if(!prices[product?.productId]) return
            setPrice(prices[product?.productId][country?.node?.country_iso_code])
            setBaseprice(prices[product?.productId][BASE_COUNTRY_ISO])
        } catch (e) {
            console.error(`${e}, Product: ${product.product_name}, Country: ${country?.node?.country_iso_code}, Price: ${prices[product?.productId][country?.node?.country_iso_code].price}`)
        }
    }, [product, country, prices, promotions])
    
    return { price, baseprice }
}
