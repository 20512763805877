// FRAMEWORK
import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { useInfiniteHits, useSearchBox } from 'react-instantsearch'

// APP
import ProductCardMini from '../components/product-card-mini'
import useGetAllProduct from '../hooks/useGetAllProduct'
import AppContext from '../context/app'

// BOOTSTRAP
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import closeIcon from '../images/svgs/x-close-icon-white.svg'

export default function Results() {
    const { searchClicked, setSearchClicked } = useContext(AppContext)
    const allProduct = useGetAllProduct()
    const defaultResults = ['ARSF1','ARSC1','MP044']

    // https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/#hook-usage
    const { hits, results } = useInfiniteHits()
    const { clear } = useSearchBox()
    
    if (!results.query && !searchClicked) return <></>
    return (
        <Container fluid
            className='position-fixed d-flex start-0 pt-md-5 pt-3'
            style={{
                zIndex: 2000,
                top: 54,
                height: `calc(100% - 54px)`,
                background: `linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.90) 50.07%, rgba(0, 0, 0, 0.50) 100%)`,
            }}
        >
            <Container>
                {/* SUGGESTED PRODUCTS LINE */}
                <p className='text-secondary ff-primary fw-bold'>
                    SUGGESTED PRODUCTS
                </p>

                {/* 3 MINI PRODUCT CARDS */}
                <Row md={3}>
                    {
                        results?.query ?
                            hits.slice(0, 3).map(hit => {
                                const product = allProduct.find(({ node }) => hit.sku === node.sku)
                                return <ProductCardMini product={product?.node} onClick={clear} />
                            })
                            :
                            defaultResults.map(sku => {
                                const product = allProduct.find(({ node }) => sku === node.sku)
                                return <ProductCardMini product={product?.node} onClick={clear} />
                            })
                    }
                </Row>

                {/* VIEW ALL RESULTS BUTTON */}
                <p className='flex-center gap-3 mb-0 mt-md-4'>
                    <Button
                        style={{ fontSize: 18, width: 312 }}
                        className="inline-flex-center gap-1 bg-transparent ff-primary text-white text-uppercase border border-white border-2"
                        onClick={() => {
                            clear()
                            setSearchClicked(false)
                        }}
                        title='Press (esc) to close'
                    >
                        <img src={closeIcon} alt='' height={24} />
                        CLOSE SEARCH
                    </Button>
                    <Button
                        style={{ fontSize: 18, width: 312 }}
                        className="d-inline-block bg-primary ff-primary text-uppercase border border-primary border-2"
                        onClick={() => {
                            navigate('/search', { state: results })
                            clear()
                            setSearchClicked(false)
                        }}
                    >
                        VIEW ALL SEARCH RESULTS ({results.nbHits})
                    </Button>
                </p>
            </Container>
        </Container>
    )
}
